var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card-title', {
    staticClass: "my-1"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('feather-icon', {
    staticClass: "mx-50",
    attrs: {
      "icon": "UserIcon",
      "size": "24"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('agency.agencyDetail')))])], 1), _c('div', [_vm.readonly ? [_c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "info",
      "disabled": !_vm.canAccess('agency.updateAgency')
    },
    on: {
      "click": _vm.handleEdit
    }
  }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('edit')))])])] : _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "outline-info",
      "disabled": !_vm.canAccess('agency.updateAgency')
    },
    on: {
      "click": _vm.handleCancelEdit
    }
  }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('cancelEdit')))])])], 2)])]), _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.updatedAgencyHandle);
            }
          }
        }, [_c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.agencyInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-media', {
          staticClass: "mt-1",
          attrs: {
            "no-body": ""
          }
        }, [_vm.readonly ? _c('b-media-aside', {
          staticClass: "mr-0 position-relative"
        }, [_c('b-avatar', {
          attrs: {
            "size": "70",
            "src": _vm.agencyToEdit.avatar,
            "variant": "light-success",
            "height": "70"
          }
        })], 1) : _c('b-media-aside', {
          staticClass: "mr-0 position-relative"
        }, [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.$refs.refAgencyAvatar.$el.click();
            }
          }
        }, [_c('b-avatar', {
          attrs: {
            "size": "70",
            "src": _vm.agencyToEdit.avatar,
            "variant": "light-success",
            "height": "70"
          }
        })], 1), _c('b-button', {
          staticClass: "position-absolute",
          staticStyle: {
            "padding": "4px",
            "top": "-10px",
            "right": "-10px"
          },
          attrs: {
            "variant": "light",
            "size": "sm",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clearAgencyAvatar();
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1), _c('b-form-file', {
          ref: "refAgencyAvatar",
          attrs: {
            "accept": ".jpg, .png",
            "hidden": true,
            "plain": ""
          },
          on: {
            "input": _vm.inputImageFormAgency
          },
          model: {
            value: _vm.agencyAvatar,
            callback: function callback($$v) {
              _vm.agencyAvatar = $$v;
            },
            expression: "agencyAvatar"
          }
        })], 1), _c('b-media-body', {
          staticClass: "mt-75 ml-75"
        }, [_c('b-card-text', {
          staticClass: "text-heading-5 mb-25"
        }, [_vm._v(" " + _vm._s(_vm.agencyToEdit.agencyName) + " ")]), _c('b-card-text', [_vm._v(_vm._s(_vm.agencyToEdit.agencyCode.toUpperCase()))])], 1)], 1), _c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.bookerId'),
            "rules": "bookerId|min:5"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('agency.bookerId'),
                  "label-for": "bookerId"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "bookerId",
                  "name": _vm.$t('agency.bookerId'),
                  "disabled": !_vm.canEditBookerId || _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.bookerId')
                },
                model: {
                  value: _vm.agencyToEdit.bookerId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "bookerId", $$v);
                  },
                  expression: "agencyToEdit.bookerId"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelOwner'),
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agencyOwner"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelOwner')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agencyOwner",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderOwner')
                },
                model: {
                  value: _vm.agencyToEdit.agencyOwner.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit.agencyOwner, "name", $$v);
                  },
                  expression: "agencyToEdit.agencyOwner.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelEmail'),
            "rules": "required|email|max:50",
            "mode": "eager"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "emailAddress"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelEmail')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "emailAddress",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": "300",
                  "placeholder": _vm.$t('agency.placeholderEmail')
                },
                model: {
                  value: _vm.agencyToEdit.emailAddress,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "emailAddress", $$v);
                  },
                  expression: "agencyToEdit.emailAddress"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelOwnerPhone'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "ownerPhone"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelOwnerPhone')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "ownerPhone",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderOwnerPhone')
                },
                model: {
                  value: _vm.agencyToEdit.agencyOwner.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit.agencyOwner, "phoneNumber", $$v);
                  },
                  expression: "agencyToEdit.agencyOwner.phoneNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.birthday')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "birthday"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.birthday')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                staticClass: "form-control",
                attrs: {
                  "name": "birthday",
                  "placeholder": _vm.$t('customer.placeholderDoB'),
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": new Date(1920, 0, 1),
                  "max": new Date(),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "locale": "vi",
                  "no-flip": ""
                },
                model: {
                  value: _vm.agencyToEdit.birthDayOwner,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "birthDayOwner", $$v);
                  },
                  expression: "agencyToEdit.birthDayOwner"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelIdentityCardNum'),
            "rules": "max:15"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "identityCardNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelIdentityCardNum')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "identityCardNumber",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderIdentityCardNum')
                },
                model: {
                  value: _vm.agencyToEdit.identityCardNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "identityCardNumber", $$v);
                  },
                  expression: "agencyToEdit.identityCardNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelIdentityCardName'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "identityCardName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelIdentityCardName')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "identityCardName",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "maxlength": "255",
                  "placeholder": _vm.$t('agency.placeholderIdentityCardName')
                },
                model: {
                  value: _vm.agencyToEdit.identityCardName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "identityCardName", $$v);
                  },
                  expression: "agencyToEdit.identityCardName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelPlaceOfIssue'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "placeOfIssue"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelPlaceOfIssue')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "placeOfIssue",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "maxlength": "255",
                  "placeholder": _vm.$t('agency.placeholderPlaceOfIssue')
                },
                model: {
                  value: _vm.agencyToEdit.placeOfIssue,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "placeOfIssue", $$v);
                  },
                  expression: "agencyToEdit.placeOfIssue"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "refProviderIDFrontImageFile",
          attrs: {
            "name": _vm.$t('agency.uploadFrontId')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "frontIdFrontImage"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.uploadFrontId')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-file', {
                ref: "refInputFrontIDPhoto",
                attrs: {
                  "id": "frontIdFrontImage",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "accept": ".jpg, .png",
                  "plain": "",
                  "placeholder": "Chưa chọn ảnh",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.inputImageIDCardFrontFormData
                },
                model: {
                  value: _vm.agencyFile.idFrontImage,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "idFrontImage", $$v);
                  },
                  expression: "agencyFile.idFrontImage"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "refProviderIDBackImageFile",
          attrs: {
            "name": _vm.$t('agency.uploadBackId')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "frontIdBackImage"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.uploadBackId')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-file', {
                ref: "refInputBackIDPhoto",
                attrs: {
                  "id": "frontIdBackImage",
                  "accept": ".jpg, .png",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "plain": "",
                  "placeholder": "Chưa chọn ảnh",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.inputImageIDCardBackFormData
                },
                model: {
                  value: _vm.agencyFile.idBackImage,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "idBackImage", $$v);
                  },
                  expression: "agencyFile.idBackImage"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "5",
            "md": "6",
            "centered": ""
          }
        }, [_vm.agencyToEdit.identityCardFrontImage ? _c('b-img', {
          staticClass: "border shadow-lg mb-1",
          staticStyle: {
            "max-height": "200px"
          },
          attrs: {
            "src": _vm.agencyToEdit.identityCardFrontImage,
            "fluid": "",
            "alt": "id card"
          }
        }) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "cols": "5",
            "md": "6",
            "centered": ""
          }
        }, [_vm.agencyToEdit.identityCardBackImage ? _c('b-img', {
          staticClass: "border shadow-lg mb-1",
          staticStyle: {
            "max-height": "200px"
          },
          attrs: {
            "src": _vm.agencyToEdit.identityCardBackImage,
            "fluid": "",
            "alt": "id card"
          }
        }) : _vm._e()], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.agencyBusinessInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyCompanyName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agency-name"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.agencyCompanyName')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agency-name",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "name": _vm.$t('agency.agencyCompanyName'),
                  "placeholder": "".concat(_vm.$t('agency.enter'), " ").concat(_vm.$t('agency.agencyCompanyName')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.agencyToEdit.agencyName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "agencyName", $$v);
                  },
                  expression: "agencyToEdit.agencyName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Tax Code",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "tax-code"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelTaxCode')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "tax-code",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderTaxCode')
                },
                model: {
                  value: _vm.agencyToEdit.taxCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "taxCode", $$v);
                  },
                  expression: "agencyToEdit.taxCode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelMonthlyReport'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "enableSendMonthlyReport",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelMonthlyReport')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [!_vm.readonly ? _c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "options": [{
                    label: 'Yes',
                    value: true
                  }, {
                    label: 'No',
                    value: false
                  }],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "enableSendMonthlyReport",
                  "placeholder": _vm.$t('agency.placeholderMonthlyReport')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.enableSendMonthlyReport,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "enableSendMonthlyReport", $$v);
                  },
                  expression: "agencyToEdit.enableSendMonthlyReport"
                }
              }) : _c('b-form-input', {
                attrs: {
                  "value": _vm.$t([{
                    label: 'Yes',
                    value: true
                  }, {
                    label: 'No',
                    value: false
                  }].find(function (item) {
                    return item.value === _vm.agencyToEdit.enableSendMonthlyReport;
                  }).label),
                  "disabled": ""
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyCode'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('agency.agencyCode'),
                  "label-for": "agencyCode"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agencyCode",
                  "value": _vm.agencyToEdit.agencyCode.toUpperCase(),
                  "disabled": _vm.readonly || true,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.upperCaseFormatter,
                  "placeholder": _vm.$t('agency.agencyCode')
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "agency-address",
            "label": _vm.$t('myAccount.addressCompany')
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "agency-address",
            "disabled": _vm.readonly || !_vm.canEdit,
            "name": _vm.$t('myAccount.addressCompany'),
            "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.addressCompany'))
          },
          model: {
            value: _vm.agencyToEdit.agencyAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyToEdit, "agencyAddress", $$v);
            },
            expression: "agencyToEdit.agencyAddress"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyFoundingDate')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('agency.agencyFoundingDate')),
                  "label-for": "agencyFoundingDate"
                }
              }, [_c('b-form-datepicker', {
                staticClass: "form-control",
                attrs: {
                  "name": _vm.$t('agency.agencyFoundingDate'),
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "placeholder": _vm.$t('agency.agencyFoundingDate'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": new Date(1920, 0, 1),
                  "max": new Date(),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "locale": "vi",
                  "no-flip": ""
                },
                model: {
                  value: _vm.agencyToEdit.agencyFoundingDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "agencyFoundingDate", $$v);
                  },
                  expression: "agencyToEdit.agencyFoundingDate"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.paymentMethod'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "paymentMethod",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.paymentMethod')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "options": [{
                    label: 'overdraft',
                    value: 'OVERDRAFT'
                  }, {
                    label: 'prepay',
                    value: 'PREPAY'
                  }],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "paymentMethod",
                  "placeholder": _vm.$t('agency.paymentMethod')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.paymentMethod,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "paymentMethod", $$v);
                  },
                  expression: "agencyToEdit.paymentMethod"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "documentContractFile",
          attrs: {
            "name": "".concat(_vm.$t('agency.documentContract'))
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "documentContractUrlFile"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_vm._v(" " + _vm._s(_vm.$t('agency.documentContract')) + " "), _c('a', {
                      attrs: {
                        "href": _vm.agencyToEdit.documentContractUrl,
                        "alt": "download file",
                        "target": "_blank"
                      }
                    }, [_vm._v(" Tải file ")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-file', {
                ref: "refInputDocumentContractUrl",
                attrs: {
                  "id": "documentContractUrlFile",
                  "accept": ".pdf, .docx, .doc, .txt",
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "plain": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.inputDocumentContractFormData
                },
                model: {
                  value: _vm.agencyFile.documentContract,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "documentContract", $$v);
                  },
                  expression: "agencyFile.documentContract"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _vm.agencyToEdit.id !== 1000000 ? _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "font-weight-bold",
          attrs: {
            "label-for": "agency-limit-credit",
            "label": "".concat(_vm.$t('agency.labelWarningCredit'), " / ").concat(_vm.$t('agency.labelLimitCredit'))
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "text-dark font-medium-1 font-weight-bolder ml-75 mr-2"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.agencyToEdit.warningCredit)) + " / " + _vm._s(_vm.formatCurrency(_vm.agencyToEdit.limitCredit)) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(0, 207, 232, 0.15)',
            expression: "'rgba(0, 207, 232, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 py-50 rounded-lg",
          attrs: {
            "variant": "outline-primary",
            "disabled": _vm.readonly && !['KTT', 'KTV'].includes(_vm.meData.type) || !_vm.canEdit
          },
          on: {
            "click": function click($event) {
              return _vm.openChangeBalanceModal(_vm.agencyToEdit);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('adjust')) + " ")])], 1)])], 1) : _vm._e()], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.labelStatus')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelStatus'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "status",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelStatus')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.statusAgencyOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "input-id": "status",
                  "placeholder": _vm.$t('agency.placeholderStatus')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.status,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "status", $$v);
                  },
                  expression: "agencyToEdit.status"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.airAccountForAgencyText')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('h5', {
          staticClass: "my-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.provideAccount')) + " ")]), _c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelAirUsername'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "airAccountUsername"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelAirUsername')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "airAccountUsername",
                  "options": [],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "clearable": "",
                  "input-id": "airAccountUsername",
                  "placeholder": _vm.$t('agency.placeholderAirUsername')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.airAccount,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "airAccount", $$v);
                  },
                  expression: "agencyToEdit.airAccount"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelAirline'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "airline",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelAirline')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.airlineOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "disabled": _vm.readonly || !_vm.canEdit,
                  "input-id": "airline",
                  "placeholder": _vm.$t('agency.placeholderAirline')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.airline,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "airline", $$v);
                  },
                  expression: "agencyToEdit.airline"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.configs.title')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0 d-flex-center justify-content-start mt-1"
        }, [_c('b-row', {
          staticClass: "w-100 gap-3"
        }, [_vm.isRoleF1 || _vm.isRoleF3 && _vm.isF3Retail ? _c('b-col', {
          staticClass: "d-flex-center justify-content-start",
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.readonly
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeDisableDraft(_vm.agencyToEdit.enableDraft);
            }
          },
          model: {
            value: _vm.agencyToEdit.enableDraft,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyToEdit, "enableDraft", $$v);
            },
            expression: "agencyToEdit.enableDraft"
          }
        }), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.agencyToEdit.enableDraft ? _vm.$t('agency.configs.enableDraft') : _vm.$t('agency.configs.disableDraft')) + " ")])], 1) : _vm._e(), !_vm.isRoleF1 ? _c('b-col', {
          staticClass: "d-flex-center justify-content-start",
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.agencyToEdit.autoIssueTicket,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyToEdit, "autoIssueTicket", $$v);
            },
            expression: "agencyToEdit.autoIssueTicket"
          }
        }), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.agencyToEdit.autoIssueTicket ? _vm.$t('agency.configs.autoIssueTicket') : _vm.$t('agency.configs.disableAutoIssueTicket')) + " ")])], 1) : _vm._e(), _vm.isRoleF3 && _vm.isF3Retail && _vm.agencyToEdit.agencyRetailConfig ? _c('b-col', {
          staticClass: "d-flex-center justify-content-start",
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.agencyToEdit.agencyRetailConfig.usePhoneCustomer,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyToEdit.agencyRetailConfig, "usePhoneCustomer", $$v);
            },
            expression: "agencyToEdit.agencyRetailConfig.usePhoneCustomer"
          }
        }), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.agencyToEdit.agencyRetailConfig.usePhoneCustomer ? _vm.$t('agency.configs.usePhoneCustomer') : _vm.$t('agency.configs.disableUsePhoneCustomer')) + " ")])], 1) : _vm._e(), _vm.isRoleF3 && _vm.isF3Retail && _vm.agencyToEdit.agencyRetailConfig ? _c('b-col', {
          staticClass: "d-flex-center justify-content-start",
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.agencyToEdit.agencyRetailConfig.showTicketCondition,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyToEdit.agencyRetailConfig, "showTicketCondition", $$v);
            },
            expression: "agencyToEdit.agencyRetailConfig.showTicketCondition"
          }
        }), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.agencyToEdit.agencyRetailConfig.showTicketCondition ? _vm.$t('agency.configs.showTicketCondition') : _vm.$t('agency.configs.disableShowTicketCondition')) + " ")])], 1) : _vm._e()], 1), _vm.isRoleF3 && _vm.agencyToEdit.parentAgency && _vm.agencyToEdit.parentAgency.id !== 1000000 ? _c('b-row', {
          staticClass: "w-100"
        }, [_c('b-col', {
          staticClass: "d-flex-center justify-content-start mb-75 mb-md-0",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "custom-control-success",
          attrs: {
            "checked": _vm.agencyToEdit.hideFee,
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.readonly || !_vm.canEdit || _vm.isF3Retail
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeHideFeeConfig(_vm.agencyToEdit.id);
            }
          }
        }), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.agencyToEdit.hideFee ? _vm.$t('agency.configs.hideFee') : _vm.$t('agency.configs.showFee')) + " ")])], 1), _vm.agencyToEdit.parentAgency && _vm.agencyToEdit.parentAgency.id !== 1000000 && _vm.isRoleF2 ? _c('b-col', {
          staticClass: "d-flex-center justify-content-start",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "custom-control-success",
          attrs: {
            "checked": _vm.agencyToEdit.sendMailAirline,
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.readonly || !_vm.canEdit
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeSendMailAirline(_vm.agencyToEdit.id);
            }
          }
        }), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.agencyToEdit.sendMailAirline ? _vm.$t('agency.configs.sendMailAirlineOn') : _vm.$t('agency.configs.sendMailAirlineOff')) + " ")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-50 mb-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h6', {
                staticClass: "text-airline font-medium-3 m-0"
              }, [_vm._v(" Mạng xã hội ")]), !_vm.readonly ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }, {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary.window",
                  modifiers: {
                    "hover": true,
                    "v-primary": true,
                    "window": true
                  }
                }],
                staticClass: "btn-icon mr-1 py-25 px-50 d-flex-center",
                attrs: {
                  "title": "Chỉnh sửa",
                  "size": "sm",
                  "variant": "outline-info"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editSocialNetwork(_vm.agencyToEdit);
                  }
                }
              }, [_c('img', {
                attrs: {
                  "src": require("@icons/edit.svg")
                }
              }), _c('span', {
                staticClass: "font-medium-2 ml-25",
                staticStyle: {
                  "margin-top": "1px"
                }
              }, [_vm._v(" Thay đổi mạng xã hội ")])]) : _vm._e()], 1)];
            },
            proxy: true
          }], null, true)
        }, [_vm.agencyToEdit.socialAccounts && _vm.agencyToEdit.socialAccounts.length ? _c('div', _vm._l(_vm.agencyToEdit.socialAccounts, function (item, i) {
          return _c('div', {
            key: i,
            staticClass: "mb-50 d-flex align-items-center"
          }, [_c('span', {
            staticClass: "mr-50 font-weight-bolder",
            staticStyle: {
              "min-width": "120px"
            }
          }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('b-link', {
            staticClass: "url-ellipsis",
            attrs: {
              "href": item.url,
              "target": "_blank"
            }
          }, [_vm._v(" " + _vm._s(item.url) + " ")])], 1);
        }), 0) : _c('div', [_vm._v(" Chưa thêm mạng xã hội ")])]), _vm.isRoleF1 ? _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-50 mb-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h6', {
                staticClass: "text-airline font-medium-3 m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.note')) + " ")]), !_vm.readonly ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }, {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary.window",
                  modifiers: {
                    "hover": true,
                    "v-primary": true,
                    "window": true
                  }
                }],
                staticClass: "btn-icon mr-1 py-25 px-50 d-flex-center",
                attrs: {
                  "title": "Chỉnh sửa",
                  "size": "sm",
                  "variant": "outline-info"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editNote($event.target);
                  }
                }
              }, [_c('img', {
                attrs: {
                  "src": require("@icons/edit.svg")
                }
              }), _c('span', {
                staticClass: "font-medium-2 ml-25",
                staticStyle: {
                  "margin-top": "1px"
                }
              }, [_vm._v(" Sửa ghi chú ")])]) : _vm._e()], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.agencyToEdit.note || _vm.$t('reservation.noNote')) + " ")])]) : _vm._e(), _c('AppCollapse', {
          attrs: {
            "type": "border-info card"
          }
        }, [_c('AppCollapseItem', {
          staticClass: "border-info",
          attrs: {
            "title": "",
            "class-header": "card-header bg-light-info"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('span', {
                staticClass: "text-dark font-weight-bolder"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.auditLog')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-table-lite', {
          staticClass: "mb-1 rounded",
          attrs: {
            "bordered": "",
            "responsive": "",
            "sticky-header": true,
            "show-empty": "",
            "empty-text": _vm.$t('noMatchingResult'),
            "no-border-collapse": "",
            "small": "",
            "thead-class": "text-dark font-weight-bolder text-nowrap",
            "items": _vm.agencyToEdit.auditLogs,
            "fields": _vm.tableColumns
          },
          scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
            return {
              key: "head(".concat(column, ")"),
              fn: function fn(data) {
                return [_c('span', {
                  key: column,
                  staticClass: "text-dark"
                }, [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.column))) + " ")])];
              }
            };
          }), {
            key: "cell(no)",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
            }
          }, {
            key: "cell(paxUpdated)",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('div', {
                staticClass: "fw-700 text-nowrap"
              }, [_vm._v(" " + _vm._s(item.modifiedBy.firstName) + " " + _vm._s(item.modifiedBy.lastName) + " ")])];
            }
          }, {
            key: "cell(action)",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('div', [_vm._v(" " + _vm._s(item.action) + " ")])];
            }
          }, {
            key: "cell(updatedData)",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [item.newValue ? _c('div', [['ENABLE_BOOKING_GROUP', 'UPDATE_SEND_EMPLOYEE_MAIL_AIRLINE'].includes(item.action) ? [_vm._v(" " + _vm._s(_vm.$te(item.newValue) ? _vm.$t(item.newValue) : item.newValue) + " ")] : !item.action.includes('BALANCE') ? [_c('div', _vm._l(JSON.parse(item.newValue), function (newVal, key) {
                return _c('div', {
                  key: key
                }, [_vm.checkTypeValueAuditLog(newVal) ? _vm._l(newVal, function (val, key2) {
                  return _c('div', {
                    key: key2
                  }, [_vm._v(" " + _vm._s(key2) + ": " + _vm._s(val) + " ")]);
                }) : _c('span', [_vm._v(" " + _vm._s(key) + ": " + _vm._s(newVal) + " ")])], 2);
              }), 0)] : Number(item.newValue) ? [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.newValue)))])] : _vm._e()], 2) : _vm._e()];
            }
          }, {
            key: "cell(updatedAt)",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).dateTime) + " ")])];
            }
          }], null, true)
        })], 1)], 1), _c('div', {
          staticClass: "button_actions d-flex mt-1 justify-content-center gap-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": _vm.onCancel
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), !_vm.readonly ? _c('b-button', {
          attrs: {
            "variant": "info",
            "disabled": invalid
          },
          on: {
            "click": function click($event) {
              handleSubmit(function () {
                return _vm.updatedAgencyHandle(true);
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]) : _vm._e()], 1)], 1)];
      }
    }])
  }), _c('AgenciesChangeBalanceModal', {
    attrs: {
      "agencies-data": _vm.agencyToEdit,
      "limit-credit": _vm.agencyToEdit.limitCredit,
      "warning-credit": _vm.agencyToEdit.warningCredit,
      "is-role-f2": _vm.isRoleF2,
      "me-data": _vm.meData
    },
    on: {
      "update:limitCredit": function updateLimitCredit($event) {
        return _vm.$set(_vm.agencyToEdit, "limitCredit", $event);
      },
      "update:limit-credit": function updateLimitCredit($event) {
        return _vm.$set(_vm.agencyToEdit, "limitCredit", $event);
      },
      "update:warningCredit": function updateWarningCredit($event) {
        return _vm.$set(_vm.agencyToEdit, "warningCredit", $event);
      },
      "update:warning-credit": function updateWarningCredit($event) {
        return _vm.$set(_vm.agencyToEdit, "warningCredit", $event);
      }
    }
  }), _vm.agencyToEdit ? _c('NoteModal', {
    attrs: {
      "agency-to-edit": _vm.agencyToEdit,
      "note": _vm.agencyToEdit.note,
      "agencies-data": _vm.agenciesData
    },
    on: {
      "update:note": function updateNote($event) {
        return _vm.$set(_vm.agencyToEdit, "note", $event);
      }
    }
  }) : _vm._e(), _vm.agencyToEdit ? _c('ModalAddSocialNetwork', {
    attrs: {
      "agency-to-edit": _vm.agencyToEdit,
      "social-accounts": _vm.agencyToEdit.socialAccounts,
      "agencies-data": _vm.agenciesData
    },
    on: {
      "update:socialAccounts": function updateSocialAccounts($event) {
        return _vm.$set(_vm.agencyToEdit, "socialAccounts", $event);
      },
      "update:social-accounts": function updateSocialAccounts($event) {
        return _vm.$set(_vm.agencyToEdit, "socialAccounts", $event);
      }
    }
  }) : _vm._e(), _vm.agencyToEdit ? _c('ModalDisableDraft', {
    attrs: {
      "agency-to-edit": _vm.agencyToEdit
    },
    on: {
      "update-enable-draft": _vm.updateEnableDraft
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }